import React from "react"

import LayoutComponent from "../components/layout/LayoutComponent"
import SeoComponent from "../components/seo/SeoComponent"
import { Link } from "gatsby"

const NotFoundPage: React.FC = () => (
  <LayoutComponent bottomPaddingDisabled={true}>
    <SeoComponent title="404: Not found" />
    <div className="flex-1 flex flex-col items-center">
      <p className="text-6xl font-bold">404</p>
      <p className="text-2xl font-light mb-5">It seems you got lost...</p>
      <div className="flex-grow mb-3">
        <Link
          to={`/`}
          className="border-solid border-2 border-gray-600 hover:bg-black hover:text-white hover:border-transparent font-bold py-2 px-4 rounded-full"
        >
          Go back
        </Link>
      </div>
      <img className="h-64" src={"/john.gif"} alt="keyboard" />
    </div>
  </LayoutComponent>
)

export default NotFoundPage
